import matchtitle from '../../components/matchtitle';
import {
	Message, Button
} from 'element-ui'

import Receive from './receive.vue';
import {
	handlePageTeamPrizeSituation,
	handleUpdateTeamPrizeReceiveFlag,
	handleQueryPrizeSummary,
	handleDeleteAllPrizesByMatchId
} from '@index/api/mymatchs/transcript';
import {
	handlePageQuery,
	handleDeleteScoreTable,
} from '@index/api/mymatchs/scoretable';
import {
	handleStreamlineDetails
} from '@index/api/mymatchs/matchs';
export default {
	name: "transcript",
	components: {
		matchtitle
	},
	data() {
		return {
			dialogVisible: false,
			receivePrizeForm: { receiveFlag: '0' },
			currTeamId: '',
			scoreForm: {
				matchId: sessionStorage.getItem("currMatchId"),
				con: '',
				currPage: 1,
				pageSize: 20
			},
			matchId: sessionStorage.getItem("currMatchId"),
			havAuth: false,
			teamForm: {
				matchId: sessionStorage.getItem("currMatchId"),
				con: '',
				currPage: 1,
				pageSize: 20
			},
			tableDataInfo: {
				list: []
			},
			currentRowKey: 0,
			scoreTableInfo: { data: [] },
			prizesTableData:[],
			currMatch:{},
			currTeam:{},
			teamAllScores:[]


		}
	},
	created() {
		this.pageTeamPrizeSituation();
		this.streamlineDetails();
	},
	mounted() {
		this.init();
	},
	beforeDestroy() {

	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;
		},
		init() {			
			if (this.tableDataInfo && this.tableDataInfo.list.length > 0) {
				this.$refs.unitRef.setCurrentRow(this.tableDataInfo.list[0]);				
			}

		},
		streamlineDetails(){
			handleStreamlineDetails(this.$data.matchId).then(res=>{				
				this.$data.currMatch=res.data;				
			});
		},
		getCurrRow(index) {
			return this.$createElement("div", {
				class: "arrow-icon",
				style: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}
			}, [
				this.$createElement("img", {
					attrs: {
						src: "/static/arrow.png",
						width: "100%",
						height: '24px',
					}
				})
			])
			// if(index==this.currentRowKey){
			// }else{
			//     this.$createElement("div",'');
			// }
		},

		
		printPrizes() {
			this.queryTeamAllScores();
			
		},
		rowReceive(row) {
			this.$data.receivePrizeForm = row;
			this.$data.dialogVisible = true;
		},
		refreshTeamData() {
			this.$data.teamForm.currPage = 1;
			this.$data.teamForm.con = "";
			this.pageTeamPrizeSituation();
		},
		teamSizeChange(pageSize) {
			this.$data.teamForm.pageSize = pageSize;
			this.pageTeamPrizeSituation();

		},
		teamCurrentChange(currPage) {
			this.$data.teamForm.currPage = currPage;
			this.pageTeamPrizeSituation();
		},
		pageTeamPrizeSituation(callback) {
			var temp=null;
			handlePageTeamPrizeSituation(this.$data.teamForm).then(res => {
				this.$data.tableDataInfo = res.data;
				if (this.$data.tableDataInfo && this.$data.tableDataInfo.list) {
					temp=this.$data.tableDataInfo.list[0];
					this.$data.currTeamId = temp.teamId;
					this.currTeam={teamId:temp.teamId,teamName:(temp.teamCode+" "+temp.teamName)}
					this.queryPrizeSummary();
					this.pageQuery();
					this.init();
				}
				if (callback) {
					callback(res.data);
				}

			})
		},
		queryPrizeSummary() {
			if (!this.$data.currTeamId) {
				return;
			}
			handleQueryPrizeSummary({ matchId: this.$data.matchId, teamId: this.$data.currTeamId }).then(res => {
				this.$data.prizesTableData = res.data;
			});

		},
		handleRowClick(row, column, event) {
			this.$data.currTeamId = row.teamId;
			this.currTeam={teamId:row.teamId,teamName:(row.teamCode+" "+row.teamName)}
			this.queryPrizeSummary(row.teamId);
			this.$data.scoreForm.currPage=1;
			this.pageQuery();
		},
		saveTeamReceivePrizeEvent() {
			handleUpdateTeamPrizeReceiveFlag({
				matchId: this.$data.matchId,
				teamId: this.$data.receivePrizeForm.teamId,
				receiveFlag: this.$data.receivePrizeForm.receiveFlag,
			}).then(res => {
				if (res.status == 200) {
					this.pageTeamPrizeSituation();
					this.$data.dialogVisible = false;
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}

			})
		},
		sizeScoreChange(pageSize) {
			this.$data.scoreForm.pageSize = pageSize;
			this.pageQuery();
		},
		currentScoreChange(currPage) {
			this.$data.scoreForm.currPage = currPage;
			this.pageQuery();
		},
		searchScore() {
			this.$data.scoreForm.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			if (!this.$data.currTeamId) {
				return;
			}
			handlePageQuery({
				pageSize: this.$data.scoreForm.pageSize,
				currPage: this.$data.scoreForm.currPage,
				con: this.$data.scoreForm.con,
				teamId: this.$data.currTeamId,
				matchId: sessionStorage.getItem("currMatchId"),
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.$data.scoreTableInfo = res;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		deleteScoreTable() {
			this.$confirm('此操作将永久删除该数据件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirmButtonClass',
				type: 'warning'
			}).then(() => {
				handleDeleteScoreTable(this.$data.matchId).then(res => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: 'success',
							duration: 5 * 1000
						});
						this.pageQuery();
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		delAllPrizes() {
		this.$confirm('此操作将永久删除该数据件, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			confirmButtonClass: 'confirmButtonClass',
			type: 'warning'
		}).then(() => {
			handleDeleteAllPrizesByMatchId(this.$data.matchId).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.queryPrizeSummary();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
		},
		queryTeamAllScores(){
			handlePageQuery({
				pageSize: 50000,
				currPage: 1,
				con: "",
				teamId: this.$data.currTeamId,
				matchId: sessionStorage.getItem("currMatchId"),
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				this.$data.teamAllScores = res.data;
				var temp=[];
				this.$data.teamAllScores.forEach((item,index)=>{
					if(index<15){
						temp.push(item);
					}
				})
				this.$data.teamAllScores=temp

				localStorage.setItem("teamAllScores",JSON.stringify(res.data));
				localStorage.setItem("currMatch",JSON.stringify(this.$data.currMatch))
				localStorage.setItem("currTeam",JSON.stringify(this.$data.currTeam))
				localStorage.setItem("prizesTableData",JSON.stringify(this.$data.prizesTableData));

				window.open('/print', '_blank');
			})
		}
	}
}