import request from '@index/utils/request'

//分页查询代表队领取情况  2025-01-20
export function handlePageTeamPrizeSituation(data) {
  return request({
    url: 'teamPrizeSituation/pageTeamPrizeSituation',
    method: 'post',
    data
  })
}

//更新领奖状态标志  2025-01-20
export function handleUpdateTeamPrizeReceiveFlag(data) {
  return request({
    url: 'teamPrizeSituation/updateTeamPrizeReceiveFlag',
    method: 'post',
    data
  })
}


//查询奖品汇总 2025-01-20
export function handleQueryPrizeSummary(data) {
  return request({
    url: 'teamPrizeSituation/queryPrizeSummary',
    method: 'post',
    data
  })
}


//根据赛事id删除所有的奖品  2025-01-21
export function handleDeleteAllPrizesByMatchId(matchId) {
  return request({
    url: 'matchPrize/deleteAllPrizesByMatchId',
    method: 'get',
    params:{matchId:matchId}
  })
}




