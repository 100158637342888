<template>
   <div class="page-box">
       <el-radio-group v-model="receive">
        <el-radio v-for="item in items" :key="item.key" :label="item.label">
            {{item.title}}
        </el-radio>
       </el-radio-group>
              
   </div>
</template>
<script>
export default {
   name: "receive",
   data() {
       return {
        items:[
            {key:1,label:'1',title:'未领奖'},
            {key:2,label:'2',title:'已领奖'},
        ],
        receive:'',
       }
   },
   mounted() {
      
   },
   beforeDestroy(){
      
   },
   methods: {
    confirm(callback,close){
        callback();
        close();
    }
   }
}
</script>
<style lang="scss" scoped>
   .page-box{
       width: 300px;
       height: 100px;
       display: flex;
       justify-content: center;
       align-items: center;
   }
</style>