import { render, staticRenderFns } from "./transcript.vue?vue&type=template&id=2d153180&scoped=true&"
import script from "./transcript.ts?vue&type=script&lang=ts&"
export * from "./transcript.ts?vue&type=script&lang=ts&"
import style0 from "./transcript.scss?vue&type=style&index=0&id=2d153180&lang=scss&scoped=true&"
import style1 from "./transcript.vue?vue&type=style&index=1&id=2d153180&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d153180",
  null
  
)

export default component.exports